/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:wght@400;700&display=swap');

/* Tailwind base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font family override */
.font-instrument {
  font-family: 'Instrument Serif', serif;
}
